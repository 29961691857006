import axios, { AxiosResponse } from "axios"
import { useContext } from "react"
import { AttachmentModel, CourseModel, FileModel, ImageModel, InternetUserModel, IntranetUserModel, NavigationModel, RegistrationModel, RoleModel } from "../types"
import { errorLogging, notify } from "../util"
import { ApiUrl, NotifyType, PageUrl } from "../util/app-config"
import { AppContext, defaultAppContextData } from '../contexts';
import { FoodHandlerStatus } from "../types/enum/foodhandler-status"
import { AttachmentType } from "../types/enum/attachment-type"
import { useApiService } from "."
import { MeatRegistrationModel } from "../types/Meatregistration"

export const useContextService = () => {

    const appContext = useContext(AppContext);
    const apiService = useApiService();
    
    const putContextRegistration = async (value: RegistrationModel, readOnly: boolean = false, isfoodhanler: boolean = true): Promise<void> => {
        await resetContextRegistration();
        appContext.registration = value;
        appContext.registration.dob = value.dob ? new Date(value.dob) : value.dob
        appContext.registration.readonly = readOnly
        appContext.registration.isfoodhandler = isfoodhanler
        appContext.image = {} as ImageModel;
        if (value.attachment?.fileID && value.attachment?.fileName) {
            const result = await apiService.getFile(value.attachment.fileID, value.attachment.fileName);
            if (result !== null) {
                const image: ImageModel = { imageBase64: result }
                appContext.image = image
            }
        }
    }

    const putContextRegistrationMeat = async (value: MeatRegistrationModel, readOnly: boolean = false, isfoodhanler: boolean = true): Promise<void> => {
        await resetContextRegistration();
        appContext.registrationMeat = value;
        appContext.registration.dob = value.dob ? new Date(value.dob) : value.dob
        appContext.registration.readonly = readOnly
        appContext.registration.isfoodhandler = isfoodhanler
        appContext.image = {} as ImageModel;
        if (value.attachment?.fileID && value.attachment?.fileName) {
            const result = await apiService.getFile(value.attachment.fileID, value.attachment.fileName);
            if (result !== null) {
                const image: ImageModel = { imageBase64: result }
                appContext.image = image
            }
        }
    }

    const patchContextRegistration = async (uinPassport?: string, dob?: Date): Promise<void> => {
        await resetContextRegistration();
        if (uinPassport)
            appContext.registration.uinPassport = uinPassport;
        if (dob)
            appContext.registration.dob = dob ? new Date(dob) : dob
    }

    const putContextInternetUser = async (value: InternetUserModel): Promise<void> => {
        await resetContextInternetUser();
        appContext.internetUser = value;
    }

    const putContextIntranetUser = async (value: IntranetUserModel): Promise<void> => {
        await resetContextIntranetUser();
        appContext.intranetUser = value;
    }

    const patchContextRegistrationAttachment = async (files?: FileModel[]): Promise<void> => {
        if (files && files.length > 0) {
            const file = files[0];
            const attachment: AttachmentModel = {
              fileID: file.fileID,
                fileName: file.fileName,
                extension: file.extension,
                size: file.size,
                createdOn: file.createdOn,
                type: AttachmentType.Photo,
            }
            appContext.registration.attachment = attachment;
            if (attachment?.fileID && attachment?.fileName) {
                const result = await apiService.getFile(attachment.fileID, attachment.fileName);
                if (result !== null) {
                    const image: ImageModel = { imageBase64: result }
                    appContext.image = image
                }
            }
        }
    }

    const resetContextRegistration = async() => {
        appContext.registration = {} as RegistrationModel;
    }

    const resetContextInternetUser = async() => {
        appContext.internetUser = {} as InternetUserModel;
    }

    const resetContextIntranetUser = async() => {
        appContext.intranetUser = {} as IntranetUserModel;
    }


    const resetAppContext = async() => {
        appContext.registration = {} as RegistrationModel;
        appContext.image = {} as ImageModel;
        appContext.internetUser = {} as InternetUserModel;
        appContext.intranetUser = {} as IntranetUserModel;
        appContext.navigation = {} as NavigationModel;
    }

    return {
        putContextRegistration: putContextRegistration,
        putContextRegistrationMeat: putContextRegistrationMeat,
        patchContextRegistration: patchContextRegistration,
        patchContextRegistrationAttachment: patchContextRegistrationAttachment,
        putContextInternetUser: putContextInternetUser,
        putContextIntranetUser: putContextIntranetUser,
        resetAppContext:resetAppContext
    }
}
import { ErrorMessage } from '@hookform/error-message';
import { useContext, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, } from 'react-router-dom';
import { Header, Footer, Logo, Popup, Message } from '../../components'
import { AppContext } from '../../contexts';
import { useApiService, useContextService } from '../../services';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask'
import { PageUrl } from '../../util/app-config';

declare global {
    interface Window {
        grecaptcha: any;
    }
}

function Enquiry() {
    const Site_Key = process.env.REACT_APP_SITE_KEY
    const navigate = useNavigate();
    const appContext = useContext(AppContext);
    const { getPublicEnquiry, getMeatPublicEnquiry } = useApiService();
    const { putContextRegistration, putContextRegistrationMeat } = useContextService();
    const { register, handleSubmit, control, getValues, formState: { errors } } = useForm();
    const [isInvalidPopupVisible, setIsInvalidPopupVisible] = useState<boolean>(false)
    const [isFoodHandlerNotFound, setIsFoodHandlerNotFound] = useState<boolean>(false);
    const [isFoodHandler, setFoodHandler] = useState<boolean>(true);
    const [isMessage, setMessage] = useState('');

    const onClickSearch = async (e: any) => {
        e.preventDefault();
        window.grecaptcha.ready(function () {
            window.grecaptcha.execute(Site_Key, { action: 'publicenquiry' }).then(function (token: any) {
                handleSubmit(async (data) =>
                {
                    if (isFoodHandler)
                    {
                        const result = await getPublicEnquiry(token, data.uinorpassportno, data.dob);
                        if (result)
                        {
                            await putContextRegistration(result);
                            navigate(PageUrl.EnquiryResult)
                        } else
                        {
                            setIsFoodHandlerNotFound(true);
                            setMessage('Invalid food handler!');
                        }
                    }
                    else {
                        const result = await getMeatPublicEnquiry(token, data.uinorpassportno, data.dob);
                        if (result) {
                            await putContextRegistrationMeat(result, false, false);
                            navigate(PageUrl.EnquiryMeatResult)
                        }
                        else {
                            setMessage('Invalid meat handler!');
                            setIsFoodHandlerNotFound(true);
                        }
                    }

                })()
            });
        });
    }

    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js?render=" + Site_Key
        document.body.appendChild(script)

        if (typeof window !== 'undefined') {
            // Add wogaa.js as Informal service
            const script1 = document.createElement("script")
            script1.src = "https://assets.wogaa.sg/scripts/wogaa.js";
            script1.crossOrigin = 'anonymous';
            document.body.appendChild(script1)
        }

    }, [])

    const onClickBack = () => {
        navigate(PageUrl.Home)
    }

    const handleChange = async (e: any) =>
    {
        const target = e.target.value;
        if (target == "radioFoodHandler")
            setFoodHandler(true);
        else
            setFoodHandler(false);
    }



    return (
        <div className="bg-img2">
            <Header></Header>
            <div className="container">
                <div className="row">
                    <Logo></Logo>
                    <div className="col-lg-9">
                        <div className="mid-enquiry">
                            <img src="/ClientApp/build/Images/search-filter.png" alt="" />
                            <h2>Search for Food Handlers / Meat Handlers </h2>
                            <p>Type the UIN/ Passport Number and Date of Birth to check if <br /> a person is a verified <b>FOOD HANDLER / MEAT HANDLER</b></p><br />
                            <form>
                                <div className="gray-box">
                                    <div className="row action-radio">
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <input type="radio" id="radioFoodHandler" name="radio-group" value="radioFoodHandler" defaultChecked onChange={e => handleChange(e)}  />
                                                <label htmlFor="radioFoodHandler">Food Handler</label>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <input type="radio" id="radioMeatHandler" name="radio-group" value="radioMeatHandler" onChange={e => handleChange(e)} />
                                                <label htmlFor="radioMeatHandler">Meat Handler</label>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Please enter the UIN or Passport No.
                                        </label>
                                    </div>
                                    <div className="mb-3">
                                        <input type="text" className="form-control" placeholder="UIN / Passport No." id="uinorpassportno" {...register('uinorpassportno', { required: 'UIN or Passport No is required' })} />
                                        <ErrorMessage errors={errors} name="uinorpassportno" render={({ message }) => <p className="errorMsg">{message}</p>} />
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Date of Birth
                                        </label>
                                    </div>
                                    <div className="mb-3">
                                        <Controller
                                            control={control}
                                            {...register('dob', { required: 'Date of Birth is required' })}
                                            name='dob'
                                            render={({ field }) => (
                                                <DatePicker
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    placeholderText='DD / MM / YYYY'
                                                    onChange={(date: any) => field.onChange(date)}
                                                    selected={field.value}
                                                    customInput={<MaskedInput className='form-control'
                                                        mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                        keepCharPositions={true}
                                                        guide={true}
                                                    />}
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            )} />
                                        <p>* Use the same identification number that was used to register for the <br /> Food Handler training course (UIN / Passport No.)</p>
                                        <ErrorMessage errors={errors} name="dob" render={({ message }) => <p className='errorMsg'>{message}</p>} />
                                    </div>
                                </div>
                                {isFoodHandlerNotFound && getValues("uinorpassportno") !== '' && <p><span className="errorMsg msg-bold">{isMessage}</span></p>}
                            </form>
                            <div className="row">
                                <div className="col-md-1">
                                </div>
                                <div className="col-md-6">
                                    <button className="secondary-btn" onClick={onClickBack}>Go to Main Page</button> <br /><br />
                                </div>
                                <div className="col-md-2">
                                    <button className="primary-btn" data-action='publicenquiry' onClick={e => onClickSearch(e)}>Search</button> <br /><br />
                                </div>
                                <div className="col-md-3">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <Popup
                bodyText={isMessage}
                buttonCancelText='Ok'
                isVisible={isInvalidPopupVisible}
                onClickCancel={() => setIsInvalidPopupVisible(false)}
            >
            </Popup>
        </div>
    );
}

export default Enquiry;

export class ApiUrl {
    static readonly PublicEnquery = 'enquiry'
    static readonly PublicEnqueryInternet = 'enquiryinfo'
    static readonly PublicMeatEnquery = 'enquiry/meatenquiry'
    static readonly PublicMeatEnqueryInternet = 'enquiryinfo/meatenquiry'
    static readonly Enquery = 'foodhandler/enquiry'
    static readonly EnqueryMeat = 'meathandler/enquiry'
    static readonly Validatetp = 'foodhandler/validatetp'
    static readonly File = 'file'
    static readonly FoodHandler = 'foodhandler'
    static readonly UpdateFoodHandlerStatus = 'foodhandler/updatefoodhandlerstatus'
    static readonly Registrationdetail = 'foodhandler/registrationdetail'
    static readonly User = 'user'
    static readonly Auth = 'auth'
    static readonly Alert = 'alert'
    static readonly FileValidation = "photovalidation"
    static readonly SPAuthSettings = 'AuthSP/oidc-configurations'
    static readonly CPAuthSettings = 'AuthCP/oidc-configurations'
    static readonly MIAuthSettings = 'myinfo/getmisettings'
    static readonly CPOidcDoscoveryEndpoint = 'AuthCP/oidc-discovery-endpoint'
    static readonly CreateUserSession = 'UserSession'
    static readonly Token = 'AuthSP/token'
    static readonly CPToken = 'AuthCP/token'
    static readonly CreateMIUserSession = 'myinfo/initsession'
    static readonly MyInfoToken = 'myinfo/person'
}

export class PageUrl {
    static readonly Home = '/'
    static readonly EnquiryResult = '/enquiry-result'
    static readonly EnquiryNotFound = '/enquiry-not-found'
    static readonly EnquiryMeatResult = '/enquiry-meat-result'
    static readonly EnquiryMeatNotFound = '/enquiry-meat-not-found'
    static readonly Registration = '/registration'
    static readonly Enquiry = '/enquiry'
    static readonly Success = '/success'
    static readonly Camera = '/camera'
    static readonly Savephoto = '/savephoto'
    static readonly TempLogin = '/temp-login'
    static readonly Company = '/company'
    static readonly Confirmation = '/confirmation'
    static readonly AccessDenied = '/access-denied'
    static readonly Login = '/login'
    static readonly SPLogin = '/sp-login'
    static readonly CPLogin = '/cp-login'
    static readonly MILogin = '/mi-login'
    static readonly SPAuthCallbackFH = '/sp-callback'
    static readonly CPAuthCallback = '/cp-callback'
    static readonly MIAuthCallback = '/mi-callback'

}

export enum NotifyType {
    Info,
    Warning,
    Success,
    Error,
}

export class SessionKey {
    static readonly InternetAuthToken = 'internet-auth-token'
    static readonly AppData = 'app-data'
    static readonly AccessToken = 'access_token'
}

export class TooltipMessaage {
    static readonly Name = 'If you wish to update your name, please send an email request @sfa-admin@sfa.gov.sg with a valid reason stated.'
    static readonly Contact = 'If you wish to update your contact, please send an email request @sfa-admin@sfa.gov.sg with a valid reason stated.'
    static readonly UIN = 'If you wish to update your UIN or Passport, please send an email request @sfa-admin@sfa.gov.sg with a valid reason stated.'
}

import React, { useState, useEffect,useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthService } from '../../services';
import { ErrorMessage } from '@hookform/error-message';
import { useNavigate } from 'react-router-dom';
import { InternetUserType } from '../../types/enum/user-type';
import { notify } from '../../util';
import { ApiUrl, NotifyType, PageUrl, SessionKey } from '../../util/app-config';
import { useApiService, useContextService } from '../../services';
import { AppContext } from '../../contexts';

function MIAuthCallback() {
  let [searchParams, setSearchParams] = useSearchParams();
  const { getToken } = useAuthService();
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const { loginInternetUser, getRegistrationOnEnquiry, getRegistrationById } = useApiService();

  const [isVisiblePassword, setIsVisiblePassword] = useState(false)
  const [tokenResponseVal, setTokenResponse] = useState(false)
  const { putContextInternetUser, putContextRegistration, patchContextRegistration } = useContextService();
  const { message } = notify();

  useEffect(() => {
    var code = searchParams.get("code");
    var state = searchParams.get("state");
    var tokenRequest = {
      code: code,
      state: state
    }
    const fetchData = async () => {
      let tokenResponse = await getToken(tokenRequest, ApiUrl.MyInfoToken);
      //console.log(tokenResponse)
      if (tokenResponse && tokenResponse.isValidUser && tokenResponse.internetUser) {
        await putContextInternetUser(tokenResponse.internetUser);
        if (tokenResponse.internetUser.type == InternetUserType.FoodHandler) {
          localStorage.setItem(SessionKey.InternetAuthToken, tokenResponse.token);
          setTokenResponse(tokenResponse)
         
        } else {
          navigate(PageUrl.Company)
        }
      } else {
        message(NotifyType.Error, "User Login Failed")
      }
    }
    fetchData();
  }, [])

  useEffect(()=>{
    const getRegistrationInfo = async () => {
      if(tokenResponseVal!==null){
        const regOnEnquirey = await getRegistrationOnEnquiry(tokenResponseVal.internetUser.nric);
        if (regOnEnquirey) {
          await putContextRegistration(regOnEnquirey, true);
          navigate(PageUrl.Registration);
        }
        else {
          const regData = {
            uinPassport: tokenResponseVal.internetUser.nric,
            name: tokenResponseVal.internetUser.name,
            dob: tokenResponseVal.internetUser.dateOfBirth,
            email: tokenResponseVal.internetUser.email,
            mobile: tokenResponseVal.internetUser.mobileNumber,
          };
          await putContextRegistration(regData, true);
          navigate(PageUrl.Registration);
        }
      }

    }
    getRegistrationInfo();
  },[tokenResponseVal])

 


  return (
    <div>
      <div>Redirecting.........</div>
    </div>
  );
}

export default MIAuthCallback;
